import log from "loglevel";
import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";
import { RootStore } from "./root-store";
import Api from "@api/api-user";

class UsersStore {

    private rootStore: RootStore;

    private _toast: any;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async getUsers(data: any, callback: any, settings: any) {
        log.debug("getUsers", data, callback, settings);
        try {
            const res = await Api.users(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                callback(res.data);
            }
        } catch (ex: any) {
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async getUser(user_id: number|string) {
        log.debug("getUser", user_id);
        try {
            const res = await Api.getUser(user_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async lastLogin(user_id: number) {
        log.debug("lastLogin", user_id);
        try {
            const res = await Api.lastLogin(user_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            }
        } catch (ex: any) {
            log.debug(ex);
            //toast.error(ex.response?.data.message || ex.message);
        }
    }

    async save(data: any) {
        log.debug("save data", data);
        try {
            const res = await Api.save(data);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async ban(user_id: number) {
        log.debug("ban user_id", user_id);
        try {
            const res = await Api.ban(user_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async delete(user_id: number) {
        log.debug("delete user_id", user_id);
        try {
            const res = await Api.delete(user_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Done", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async addBalance(user_id: number, amount: number) {
        log.debug(`addBalance user_id:${user_id} amount:${amount}`);
        try {
            const res = await Api.addBalance(user_id, amount);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Success", {
                    autoClose: 500,
                });
                return res.data;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
    }

    async resetBalance(user_id: number) {
        log.debug(`resetBalance user_id:${user_id}`);
        try {
            const res = await Api.resetBalance(user_id);
            log.debug(res.data);
            if (res.data.status === 'OK') {
                toast.success("Success", {
                    autoClose: 500,
                });
                return true;
            } else {
                toast.dismiss(this._toast);
                toast.error(res.data.message);
            }
        } catch (ex: any) {
            log.debug(ex);
            toast.error(ex.response?.data.message || ex.message);
        }
        return false;
    }

}

export default UsersStore;