import mitt from 'mitt';
import { makeObservable, observable, computed, action } from "mobx";

import AuthStore from "@store/auth-store";
import LoginStore from "@store/login-store";
import MainStore from "@store/main-store";
import UsersStore from "@store/users-store";
import StatisticsStore from "@store/statistics-store";
import TransactionsStore from "@store/transactions-store";
import FinanceSettingsStore from "@store/settings-store";
import ProsStore from "@store/pros-store";
import MmnStore from "@store/mmn-store";
import EmailsStore from "@store/emails-store";
import FullzDataStore from "@store/fullz-data-store";
import FullzFormatsStore from "@store/fullz-formats-store";
import FullzDatabasesStore from "@store/fullz-databases-store";
import FullzParserStore from "@store/fullz-parser-store";
import TicketsStore from "@store/tickets-store";
import ModalTicketsStore from "@store/modal-ticket-store";
import MenuStore from "@store/menu-store";
import NewsStore from "@store/news-store";
import NotificationsStore from "@store/notifications-store";
import PagesStore from "@store/pages-store";
import ServicesStore from "@store/services-store";
import CronStore from "@store/cron-store";
import SupportStore from "@store/support-store";
import EmailsListStore from "@store/emails-list-store";


export class RootStore {

    // эмиттер для шины сообщений
    emitter = mitt();

    _showProgressBar = false;

    get showProgressBar() {
        return this._showProgressBar;
    }
    setShowProgressBar(val: boolean) {
        this._showProgressBar = val;
    }


    rootStore            = this;

    authStore            = new AuthStore(this);
    loginStore           = new LoginStore(this);
    mainStore            = new MainStore(this);

    usersStore           = new UsersStore(this);

    transactionsStore    = new TransactionsStore(this);
    statisticsStore      = new StatisticsStore(this);
    financeSettingsStore = new FinanceSettingsStore(this);

    mmnStore             = new MmnStore(this);
    emailsStore          = new EmailsStore(this);

    prosStore            = new ProsStore(this);
    fullzDataStore       = new FullzDataStore(this);
    fullzFormatsStore    = new FullzFormatsStore(this);
    fullzDatabasesStore  = new FullzDatabasesStore(this);
    fullzParserStore     = new FullzParserStore(this);

    ticketsStore         = new TicketsStore(this);
    modalTicketsStore    = new ModalTicketsStore(this);

    menuStore            = new MenuStore(this);
    newsStore            = new NewsStore(this);
    notificationsStore   = new NotificationsStore(this);
    pagesStore           = new PagesStore(this);

    servicesStore        = new ServicesStore(this);
    cronStore            = new CronStore(this);

    supportStore         = new SupportStore(this);

    emailsListStore      = new EmailsListStore(this);

    constructor() {
        makeObservable(this, {
            _showProgressBar: observable,
            showProgressBar: computed,
            setShowProgressBar: action
        });
    }

}
