import { instance } from "./service";

class ApiEmailsList {

    emails(data: any) {
        return instance.post("/data/emails_list", {
            data: data
        });
    }

    send(data: string) {
        return instance.post("/data/emails/import", { data: data });
    }

    delete(email_id: number) {
        return instance.get("/data/emails/delete/" + email_id);
    }

}

export default new ApiEmailsList();
