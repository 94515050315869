import React from "react";
import { TypeRoute } from "../types/type-route";
import { ACL } from "@config/acl";
import { USER_GROUPS } from "@config/user-groups";

const Empty = React.lazy(() => import('@views/empty/empty'));
const Main = React.lazy(() => import('@views/main/main'));
const Users = React.lazy(() => import('@views/users/users'));
const Statistics = React.lazy(() => import('@views/finance/statistics/statistics'));
const Transactions = React.lazy(() => import('@views/finance/transactions/transactions'));
const Settings = React.lazy(() => import('@views/finance/settings/settings'));
const Pros = React.lazy(() => import('@views/data/pros/pros'));
const MmnStore = React.lazy(() => import('@views/requests/mmn/mmn'));
const TrustedEmailsStore = React.lazy(() => import('@views/requests/trusted-emails/trusted-emails'));
const Tickets = React.lazy(() => import('@views/tickets/tickets'));
const Fullz = React.lazy(() => import('@views/data/fullz/fullz'));
const Emails = React.lazy(() => import('@views/emails/emails'));
const Notifications = React.lazy(() => import('@views/content/notifications/notifications'));
const Menu = React.lazy(() => import('@views/content/menu/menu'));
const News = React.lazy(() => import('@views/content/news/news'));
const Pages = React.lazy(() => import('@views/content/pages/pages'));
const SettingsServices = React.lazy(() => import('@views/settings/services/services'));
const SettingsCron = React.lazy(() => import('@views/settings/cron/cron'));
const Support = React.lazy(() => import('@views/support/support'));

/**
 * Роуты и они же меню сайдбара админки
 * Поле acl у роута определяет необходимые права доступа у юзера. Пустое поле - права не требуются
 * Поле user_groups у роута определяет группу юзеров, которым он будет доступен
 */
const routes: TypeRoute[] = [
    { path: '/main', name: 'Main', icon: 'home', element: Main },
    { path: '/users', name: 'Users', icon: 'users', element: Users },
    { path: '/finance', name: 'Finance', icon: 'dollar-sign', childs: [
        { path: '/finance/stats', name: 'Statistics', element: Statistics },
        { path: '/finance/transactions', name: 'Transactions', element: Transactions },
        { path: '/finance/transactions/user/:login', name: 'Transactions', element: Transactions, hidden: true },
        { path: '/finance/settings', name: 'Settings', element: Settings },
    ]},
    { path: '/data', name: 'Data', icon: 'table', childs: [
        { path: '/pros', name: 'PROs', element: Pros },
        { path: '/fullz', name: 'Fullz', element: Fullz },
        { path: '/emails_list', name: 'Emails', element: Emails },
    ]},
    { path: '/requests', name: 'Requests', icon: 'search', childs: [
        { path: '/mmn', name: 'MMN lookup', element: MmnStore, acl: ACL.requests_mmn },
        { path: '/emails', name: 'Trusted emails', element: TrustedEmailsStore, acl: ACL.requests_trusted_emails },
    ], acl: [ ACL.requests_mmn, ACL.requests_trusted_emails ]},
    { path: '/tickets', name: 'Tickets', icon: 'message-square', element: Tickets, acl: [
        ACL.tickets_finance, ACL.tickets_search, ACL.tickets_refunds, ACL.tickets_error, ACL.tickets_cooperation ]
    },
    { path: '/content', name: 'Content', icon: 'book', childs: [
        { path: '/menu', name: 'Main menu', element: Menu },
        { path: '/notifications', name: 'Notifications', element: Notifications, acl: ACL.add_notify },
        { path: '/news', name: 'News', element: News, acl: ACL.add_news },
        { path: '/pages', name: 'Pages', element: Pages, acl: ACL.add_pages },
    ]},
    { path: '/logs', name: 'Logs', icon: 'shield', element: Empty, user_groups: USER_GROUPS.admins },
    { path: '/settings', name: 'Settings', icon: 'settings', childs: [
        { path: '/support', name: 'Support', element: Support },
        { path: '/services', name: 'Services', element: SettingsServices },
        { path: '/cron', name: 'Cron', element: SettingsCron },
    ], user_groups: USER_GROUPS.admins },
];

export default routes;