import log from "loglevel";
import { instance } from "./service";

class ApiUser {

    users(data: any) {
        log.debug("users", data);
        return instance.post("/users", {
            data: data
        });
    }

    usersList() {
        log.debug("usersList");
        return instance.get("/users");
    }

    getUser(user_id: number|string) {
        log.debug("lastLogin", user_id);
        return instance.get("/user/" + user_id);
    }

    lastLogin(user_id: number) {
        log.debug("lastLogin", user_id);
        return instance.get("/last_login/" + user_id);
    }

    save(data: any) {
        return instance.post("/user", data);
    }

    ban(user_id: number) {
        log.debug("ban", user_id);
        return instance.get("/ban/" + user_id);
    }

    delete(user_id: number) {
        log.debug("delete", user_id);
        return instance.get("/delete/" + user_id);
    }

    addBalance(user_id: number, amount: number) {
        log.debug("addBalance", user_id, amount);
        return instance.post("/add_balance/", {
            user_id: user_id,
            amount: amount
        });
    }

    resetBalance(user_id: number) {
        log.debug("resetBalance", user_id);
        return instance.get("/reset_balance/" + user_id);
    }

}

export default new ApiUser();
